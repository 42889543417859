import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class InterceptorsService implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token') || '{}';
    if (token) {
      request = request.clone({
        headers: request.headers
          .set('Authorization', environment.basicAuth)
          .set('token', token)
          .set('appcode', 'BNPL01'),
      });
    }

    request = request.clone({
      headers: request.headers
        .set('Accept', 'application/json')
        .set('X-Frame-Options', 'SAMEORIGIN'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
        }
        return event;
      })
    );
  }
}
