import 'zone.js';  // Included with Angular CLI.
import 'reflect-metadata';  // Import for decorator metadata

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import './app/shared/utils/rxjs-operators.utils';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
