const domain =`https://api.staging.madfu.com.sa/admin/`;
const domainNew =`https://api.staging.madfu.com.sa/api/admin/`;
// const imgUrl = `https://madfu-development.s3.ap-south-1.amazonaws.com/data/merchantsetting/logo/`;
const mainUrl = `https://api.staging.madfu.com.sa/`;
const mainNewUrl = `https://api.staging.madfu.com.sa/api/`;
const basicAuth = 'Basic Ym5wbHJvb3R1c2VybmFtZTpMOG5HcTdlZEhNSlFnb1pUWkluZXN5NlptZWRNdFpOU0hRN2M3ZDRxYm96cnI=';
const countryRestrictions = {country: ["PS","SA", "EG", "IN", "NG", "US"]};

export const environment = {
  production: false,
  publicUrl: domain,
  newPublicUrl:domainNew,
  countryRestrictions,
  // imgUrl,
  mainUrl,
  mainNewUrl,
  basicAuth,
  environmentName: 'dev',
};
/*
const domain =`https://api.staging.madfu.com.sa/admin/`;
const domainNew =`https://api.staging.madfu.com.sa/api/admin/`;
const mainUrl = `https://api.staging.madfu.com.sa/`;
const mainNewUrl = `https://api.staging.madfu.com.sa/api/`;
*/
