<p class="upload-hint" *ngIf="hint">
  {{hint | replace:'{maxFileSize}':(maxFileSize/1000000)}}
</p>

<div class="px-1" *ngIf="uploadLoading">
  <p-progressBar mode="indeterminate" [style]="{ height: '5px' }"></p-progressBar>
</div>
<ng-container [ngSwitch]="multiple">
  <ng-container *ngSwitchDefault>
    <p-fileUpload #fileUploader (onSelect)="selectFilesHandler($event)" (onBeforeUpload)="uploadHandler()"
      [multiple]="multiple" [mode]="mode" [chooseLabel]="chooseLabel" [uploadLabel]="uploadLabel"
      [showUploadButton]="!uploadLoading"
      [styleClass]="(referenceControl.invalid && (referenceControl.touched || touched || submitted) ? 'border border-red-500 rounded':'')"
      uploadStyleClass="uploader-btn"
      [uploadIcon]="'pi pi-upload uploader'" [accept]="accept" [maxFileSize]="maxFileSize" [disabled]="disabled || uploadLoading"
      [fileLimit]="fileLimit" (onClear)="onClearHandler()" (onRemove)="onRemoveHandler($event)"
      [invalidFileSizeMessageSummary]="(invalidFileSizeMessageSummary| replace:'{maxFileSize}':(maxFileSize/1000000))">
    </p-fileUpload>
  </ng-container>
</ng-container>

<p class="error" *ngIf="referenceControl && referenceControl?.errors?.['custom'] && (referenceControl?.touched || submitted)">
  {{referenceControl!.errors?.['custom']}}
</p>
  <ng-container *ngIf="storedFilesList$ | async as files">
    <ng-container *ngIf="files?.length">
      <hr>
      <ng-container *ngTemplateOutlet="uploadFilesTitleTemp"></ng-container>
      <ul class="border py-2 rounded shadow-sm flex flex-column gap-2 ">
        <li *ngFor="let file of files; let i = index;" class="flex justify-content-evenly align-items-center">
          <div *ngIf="file?.fullLink! | checkExtension: '.image' as isMedia">

            <p-image styleClass="rounded" class="rounded" [src]="file?.fullLink!" [preview]="true" width="70rem" alt="media img"></p-image>
          </div>
          <span>{{file?.name}}</span>
          <span>-</span>
          <span>{{file.createdAt | date: 'dd-MM-yyyy HH:mm a'}}</span>
          <div class="flex gap-2">
            <p-button pTooltip="Remove File" (onClick)="removeStoredFile(file, i)" icon="pi pi-times"
              styleClass="p-button-rounded p-button-danger p-button-text p-button-sm"></p-button>
            <a [href]="file?.fullLink!" download
              pButton
              pTooltip="Download Image" icon="pi pi-download"
              class="p-button-rounded p-button-info p-button-text p-button-sm">
            </a>
          </div>
        </li>
      </ul>
    </ng-container>
  </ng-container>


<ng-container *ngIf="storedFilesChange$ | async"></ng-container>
<ng-template #uploadFilesTitleTemp>
  <h5 *ngIf="uploadFilesTitle">{{uploadFilesTitle}}</h5>
</ng-template>
