import { Pipe, PipeTransform } from '@angular/core';
import { ExtensionsTypes, FileImgExtensionType } from '../types/uploadfiles.types';
import { _indexEnumValue } from '../utils/enums.utils';
import { ImagesExtensionTypesEnum } from '../Enums/UPLOAD_FILES';

@Pipe({
  name: 'checkExtension',
  standalone: true,
})
export class CheckExtensionPipe implements PipeTransform {

  transform(value: string, extension: ExtensionsTypes): boolean {

    if(!value) return false;
    if(extension === ".image"){
      const ext = '.'+value.split('.').pop();
      return (ext as FileImgExtensionType) in ImagesExtensionTypesEnum;
    }
    if (value.endsWith(extension)) {
      return true;
    }
    return false;
  }

}
