import { Observable, Subject, takeUntil } from "rxjs";

declare module 'rxjs' {
    export interface Observable<T> {
        pipeUntilDestroyed(destroySubject: Subject<void>): Observable<T>;
    }
}

Observable.prototype.pipeUntilDestroyed = function <T>(this: Observable<T>, destroySubject: Subject<void>): Observable<T> {
    return this.pipe(takeUntil(destroySubject));
};
