
export enum ImagesExtensionTypesEnum{
  '.image' = '.image',
  ".jpg" = ".jpg",
  ".svg" = ".svg",
  ".gif" = ".gif",
  ".bmp" = ".bmp",
  ".tif" = ".tif",
  ".jpeg" = ".jpeg",
  ".png" = ".png",
  ".tiff" = ".tiff",
  ".webp" = ".webp",
}



export enum FileTypeEnum {
  NONE = 0,
  Default = 1,
  Template = 2,
  Email = 3,
  SMS = 4,
  Notification = 5,
  Profile = 6,
  Stories = 7,
  Activities = 8,
  Qrcode = 9,
  Offers = 10,
  MerchantSettingLogo = 11,
  MerchantSettingFavicon = 12,
  MerchantStoreLogo = 13,
  MerchantStorePicture = 14,
  Banner = 15,
  MerchantStoreItemGallery = 16,
  Merchantiban = 17,
  BankStatement = 18,
  SalaryCertificate = 19,
  UserProfile = 20,
  Others = 21,
  UserTemp = 22,
  AdminTemp = 23,
  BankAdminTemp = 24,
  VatCertificate = 25,
  CompanyRegistrationCertificate = 26
}


export enum FileExtensionTypeEnum {
  None = 0,
  IMAGE = 1,
  PDF = 2,
  DOC = 3,
  DOCX = 4,
  CSV = 5,
  HTML = 6,
  TXT = 7,
  MP4 = 8
}
